import PublicPage from '@/shared/PublicPage/PublicPage';
import { Button, Result } from 'antd';
import Link from 'next/link';

export default function NotFound() {
  return (
    <PublicPage>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link href="/" passHref>
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    </PublicPage>
  );
}
